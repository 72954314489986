import React, { useEffect, useState } from "react";
import i18next from "i18next";
import { searchForQLive, submitSearch } from "../../search/actions/searchActions";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { useParams } from "react-router-dom";
import { PARAMS } from "../../router/constants/Params";
import { setLiveSearchOpenAction } from "../actions/layoutActions";
import { useDebounce } from "../../common/scripts/hooks/useDebounce";

interface Props {
  setOpen?: any;
}

const Search: React.FC<Props> = ({ setOpen }) => {
  const params = useParams();
  const [term, setTerm] = useState<string | null>(params[PARAMS.SEARCH_TERM] ?? null);
  const debouncedTerm = useDebounce(term, 300);
  const layoutIsMobile = useSelector((state: State) => state.layout.isMobile);

  useEffect(() => {
    async function searchForQ() {
      if (debouncedTerm !== null) {
        if (!layoutIsMobile) {
          await searchForQLive(debouncedTerm);
        }
      }
    }

    searchForQ().then();
  }, [debouncedTerm, layoutIsMobile]);

  const onSubmit = async function (e) {
    if (term) {
      await submitSearch(term);
    }
    if (setOpen) {
      setOpen(false);
    }
    e.preventDefault();
  };

  const onFocus = async function () {
    if (!layoutIsMobile) {
      await setLiveSearchOpenAction(true);
    }
  };

  return (
    <div className="search ">
      <form onSubmit={onSubmit} className="form search__form">
        <input
          type="text"
          name="q"
          className="input--text"
          placeholder={i18next.t("SEARCH") + " ..."}
          aria-required="false"
          aria-label={i18next.t("SEARCH")}
          value={term ?? ""}
          onChange={e => setTerm(e.target.value)}
          onFocus={onFocus}
          onClick={onFocus}
        />
        {/* https://dragonza.io/use-debounce-in-react-hook-form-on-input-change/ */}

        <button className="icon-btn icon-btn__search" type={"submit"} tabIndex={0}>
          <span className="visuallyhidden">{i18next.t("SEARCH")}</span>
        </button>
      </form>
    </div>
  );
};

export default Search;
