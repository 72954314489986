import { dispatch } from "elstr-frontend-4/dist/ElstrCore";
import { getState } from "../../state/scripts/state";
import {
  addProductsToBuffer,
  resetBuffer,
  setCartAddresses,
  setCartAnimate,
  setCartAttributes,
  setCartCounter,
  setCartId,
  setCartLineItems,
  setCartPaymentMethods,
  setCartRelationships,
  setCartShipments,
  setCartShippingMethods,
} from "../slices/cartSlice";
import { StorageKeys } from "../../common/constants/StorageKeys";
import { serviceCreateOrder, serviceGetOrder } from "../../commercelayer/services/orders";
import { ResponseOrders, ResponseOrdersWithIncludes } from "../../commercelayer/vo/orders";
import {
  serviceCreateLineItem,
  serviceDeleteLineItem,
  serviceUpdateLineItemQuantity,
} from "../../commercelayer/services/lineItems";
import SkuQuantityItem from "../vo/SkuQuantityItem";
import { validateLineItemQuantity } from "../scripts/cart";
import { boundReplaceLocObj } from "../../router/actions/routesActions";
import { getLangCountry } from "../../router/scripts/link";
import { PATH } from "../../router/constants/Path";
import { setPathnameAfterLogin } from "../../login/slices/loginSlice";
import { ResponseShipmentsListWithIncludes } from "../../commercelayer/vo/shipments";
import { serviceGetOrderShipments } from "../../commercelayer/services/shipments";
import {
  filterAllowedPaymentMethods,
  filterIncludedAddresses,
  filterIncludedLineItems,
  filterIncludedPaymentMethods,
  filterIncludedShippingMethods,
} from "../../commercelayer/scripts/filter";
import { LineItems } from "../../commercelayer/vo/lineItems";
import { getLang } from "../../router/scripts/params";
import { gtmPushAddToCart, gtmPushAddToCartClick } from "../../common/scripts/tagManager/dataLayer";
import { scrollTop } from "../../layout/scripts/scroll";
import { OrderType } from "../../common/constants/OrderType";
import { setShowConsignmentStockInfoAction } from "../../layout/actions/layoutActions";

import { setExternalPromotionsFromOrder } from "../../discount/slices/discountPopupSlice";

export async function initCart() {
  const loginState = getState().login;
  if (loginState.token) {
    const cartId = localStorage.getItem(StorageKeys.CART_ID);
    if (cartId === null) {
      // create new draft order
      await createCart();
    } else {
      await loadCart(cartId, true);
      const cartState = getState().cart;
      // create a new cart if it is already ordered
      if (cartState.attributes?.status !== "pending" && cartState.attributes?.status !== "draft") {
        await resetCart();
        await createCart();
      }
    }
  }
}

export async function setCartIdStateAndStorage(cartId: string | null) {
  dispatch(setCartId(cartId));
  if (cartId === null) {
    localStorage.removeItem(StorageKeys.CART_ID);
  } else {
    localStorage.setItem(StorageKeys.CART_ID, cartId);
  }
}

export async function setCartCounterStateAndStorage(counter: number) {
  dispatch(setCartCounter(counter));
  localStorage.setItem(StorageKeys.CART_COUNTER, String(counter));
}

export async function setCartUpdatedAt() {
  localStorage.setItem(StorageKeys.CART_UPDATED_AT, String(new Date().getTime()));
}

export async function createCart() {
  const cart = getState().cart;
  // prevent recreating when already existent
  if (cart.id) {
    console.info("cart already exists");
    return;
  }

  const loginState = getState().login;
  const accountState = getState().account;
  if (!loginState.token) {
    return;
  }

  const response: ResponseOrders | null = await serviceCreateOrder(
    getLang(),
    accountState.customer?.id ?? "",
    OrderType.ORDER,
  );
  if (response === null) {
    return;
  }
  await setCartIdStateAndStorage(response.data.id);
  dispatch(setCartAttributes(response.data.attributes));
  await setCartCounterStateAndStorage(0);
}

export async function loadCart(cartId: string, onInit: boolean = false) {
  const responseOrder: ResponseOrdersWithIncludes | null = await serviceGetOrder(cartId);
  if (responseOrder === null) {
    return;
  }
  await setCartData(responseOrder, onInit);
  const shipmentsData = responseOrder.data.relationships.shipments.data ?? [];
  if (shipmentsData.length) {
    const responseShipments: ResponseShipmentsListWithIncludes | null =
      await serviceGetOrderShipments(cartId);
    if (responseShipments === null) {
      return;
    }
    dispatch(setCartShipments(responseShipments.data));
    dispatch(setCartShippingMethods(filterIncludedShippingMethods(responseShipments)));
  }
}

export async function addLineItem(sku: string, quantity: number) {
  await addLineItems(
    [
      {
        sku: sku,
        quantity: quantity,
      },
    ],
    true,
  );
}

export async function setCartData(order: ResponseOrdersWithIncludes, onInit: boolean = false) {
  // !!! similar function setOrderData
  if (onInit) {
    dispatch(setCartId(order.data.id));
  } else {
    await setCartIdStateAndStorage(order.data.id);
  }
  setExternalPromotionsFromOrder(order);
  dispatch(setCartAttributes(order.data.attributes));
  dispatch(setCartRelationships(order.data.relationships));
  dispatch(setCartLineItems(filterIncludedLineItems(order)));
  const paymentMethods = filterIncludedPaymentMethods(order);
  const allowedPaymentMethods = filterAllowedPaymentMethods(paymentMethods);
  dispatch(setCartPaymentMethods(allowedPaymentMethods));
  dispatch(setCartAddresses(filterIncludedAddresses(order)));
  await setCartCounterStateAndStorage(
    countAllItemsQuantity(
      filterIncludedLineItems(order).filter(lineItems => lineItems.attributes.item_type === "skus"),
    ),
  );
}

export async function addLineItems(
  skuQuantityItems: SkuQuantityItem[],
  trackClickEvent: boolean = true,
) {
  const accountState = getState().account;
  const cartState = getState().cart;

  if (trackClickEvent) {
    gtmPushAddToCartClick(skuQuantityItems);
  }

  if (accountState.customer === null) {
    dispatch(addProductsToBuffer(skuQuantityItems));
    await dispatch(setPathnameAfterLogin(window.location.pathname));
    scrollTop();
    boundReplaceLocObj({ pathname: `${getLangCountry()}/${PATH.LOGIN}` });
  } else {
    // User is logged in
    if (cartState.id == null) {
      console.error("user is logged in but not in a customer group / not assigned in SAP");
      return;
    }

    // add bounce animation
    setTimeout(function () {
      dispatch(setCartAnimate(true));
    }, 700);
    // remove bounce animation
    setTimeout(function () {
      dispatch(setCartAnimate(false));
    }, 1300);

    // set state for consignment stock warning
    // if customer has consignment stock
    // and if cart is empty
    const cst = accountState.customer?.attributes?.metadata?.cst;
    if (typeof cst === "string" && cst.length > 0) {
      if (cartState.counter === 0) {
        setShowConsignmentStockInfoAction(true);
      } else {
        if (cartState.attributes?.updated_at) {
          const updatedAt: Date = new Date(cartState.attributes?.updated_at);
          const timeDiff: number = new Date().getTime() - updatedAt.getTime();
          // >15 min in milliseconds
          if (timeDiff > 15 * 60 * 1000) {
            setShowConsignmentStockInfoAction(true);
          }
        }
      }
    }

    for (const skuQuantityItem of skuQuantityItems) {
      await serviceCreateLineItem(cartState.id, skuQuantityItem.sku, skuQuantityItem.quantity);
    }
    await loadCart(cartState.id);
    await setCartUpdatedAt();

    gtmPushAddToCart(skuQuantityItems);
  }
}

export async function updateLineItemQuantity(cartId: string, lineItemId: string, quantity: number) {
  dispatch(updateDebouncedLineItemQuantity(cartId, lineItemId, quantity));
}

function updateDebouncedLineItemQuantity(
  cartId: string,
  lineItemId: string,
  quantity: number,
): any {
  const thunk = async () => {
    await serviceUpdateLineItemQuantity(lineItemId, validateLineItemQuantity(quantity));
    await loadCart(cartId);
    await setCartUpdatedAt();
  };

  thunk.meta = {
    debounce: {
      time: 300,
      key: "updateDebouncedLineItemQuantity",
    },
  };

  return thunk;
}

export async function deleteLineItem(cartId: string, lineItemId: string) {
  await serviceDeleteLineItem(lineItemId);
  await loadCart(cartId);
  await setCartUpdatedAt();
}

export async function resetCart() {
  await setCartIdStateAndStorage(null);
  await setCartCounterStateAndStorage(0);
  dispatch(setCartAttributes(null));
  dispatch(setCartRelationships(null));
  dispatch(setCartLineItems([]));
  dispatch(setCartShipments([]));
  dispatch(setCartShippingMethods([]));
  dispatch(setCartPaymentMethods([]));
  dispatch(setCartAddresses([]));
  dispatch(resetBuffer());
}

function countAllItemsQuantity(lineItems: LineItems[]): number {
  let counter = 0;
  for (const lineItem of lineItems) {
    counter += lineItem.attributes.quantity ?? 0;
  }
  return counter;
}
