import React from "react";
import "./i18next/scripts/init";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import ElstrBaseApp from "elstr-frontend-4/dist/ElstrBaseApp";
import ElstrIo from "elstr-frontend-4/dist/ElstrIo";
import store from "./state/init/store";
import App from "./App";
import "./templates/public/css/styles.css";
import "./templates/public/fonts/webFonts/MyFontsWebfontsKit.css";
import "layout/css/common.css";
import "./common/scripts/init/sentry";
import ElstrCore from "elstr-frontend-4/dist/ElstrCore";
import { layoutReducers } from "./layout/reducer";
import { urlParamsReducers } from "./router/reducer";
import { graphcmsReducers } from "./graphcms/reducer";
import { loginReducers } from "./login/reducer";
import { accountReducers } from "./account/reducer";
import { cartReducers } from "./cart/reducer";
import { discountPopupReducers } from "./discount/reducer";
import { initLogin } from "./login/actions/loginActions";
import { setAllWindowEventListener } from "./common/scripts/window/eventListener";
import { searchReducers } from "./search/reducer";
import { commercelayerReducers } from "./commercelayer/reducer";
import { checkoutReducers } from "./checkout/reducer";
import { orderReducers } from "./order/reducer";
import { retainFocusSearchParam } from "./router/middleware/search";
import { salesagentReducers } from "./salesagent/reducer";
import { productReducers } from "./product/reducer";
import { DataLayerObject } from "./common/scripts/tagManager/dataLayer";
import { boundReplaceLocObj } from "./router/actions/routesActions";
import { getReRootPathname } from "./common/scripts/locale/reRootPathname";
import { initSearchIndex } from "./algolia/services/search";
import { consignmentStockApi } from "./consignment-stock/services/consignmentStockApi";
import { consignmentStockReducers } from "./consignment-stock/reducer";
import { hygraphApi } from "./graphcms/services/hygraphApi";
import polyfill from "./common/scripts/init/polyfill";

declare global {
  interface Window {
    dataLayer: DataLayerObject[];
  }
}

class Index extends ElstrBaseApp {
  constructor() {
    super();

    ElstrIo.SERVICE_URL = "/api/";

    this.language.disableErrors();

    this.bootApp().then();
  }

  private static getReducers() {
    return [
      urlParamsReducers,
      layoutReducers,
      graphcmsReducers,
      loginReducers,
      accountReducers,
      cartReducers,
      discountPopupReducers,
      searchReducers,
      commercelayerReducers,
      checkoutReducers,
      orderReducers,
      salesagentReducers,
      productReducers,
      consignmentStockReducers,
      { [hygraphApi.reducerPath]: hygraphApi.reducer },
    ];
  }

  private static getAppMiddleware() {
    return [retainFocusSearchParam, consignmentStockApi.middleware, hygraphApi.middleware];
  }

  private static initBeforeSetup() {
    polyfill();
    initSearchIndex();
  }

  private static async initAfterSetup() {
    if (window.location.pathname === "/") {
      boundReplaceLocObj({ pathname: await getReRootPathname() });
    }
    await initLogin();
    Index.addUrlChangeHandlers();
    setAllWindowEventListener();
  }

  // todo: check if this is needed
  private static addUrlChangeHandlers() {
    // dispatch events on URL change
    ElstrCore.history.listen(async location => {});
  }

  async bootApp() {
    Index.initBeforeSetup();

    const reducers = Index.getReducers();
    const appMiddleware = Index.getAppMiddleware();

    super.setup(reducers, store, <App />, appMiddleware);

    await Index.initAfterSetup();
  }
}

new Index();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
