import React, { useLayoutEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { useDiscountPopupPosition } from "../hooks/useDiscountPopup";
import { useOnClickOutside } from "../../common/scripts/hooks/useOnClickOutside";
import i18next from "i18next";
import { getNumberWithCurrency } from "../../common/scripts/format/number";

const DiscountPopup: React.FC = () => {
  const { position, lineItemId, external_promotions, totalAmount, totalAmountDiscount } =
    useSelector((state: State) => state.discountPopup);
  const isMobile = useSelector((state: State) => state.layout.isMobile);
  const header = document.querySelector("header");
  const headerHeight = header ? header.offsetHeight : 0;
  const [isOpen, setOpen] = useState<boolean>(!!lineItemId);
  const ref = useRef<HTMLDivElement>(null);
  const { resetLineItemId } = useDiscountPopupPosition();

  useLayoutEffect(
    function updateOpen() {
      if (lineItemId) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    },
    [lineItemId],
  );

  useOnClickOutside(ref, function closePopup(e: MouseEvent) {
    // Enable toggling the popup by clicking the button.
    // Condition true when popup open. Popup will trigger toggle. No need to close here.
    if (e?.target && "id" in e.target && e.target.id === lineItemId) {
      return;
    }
    resetLineItemId();
  });

  const { currency_code } = external_promotions;
  const lineItem = external_promotions?.line_items?.[lineItemId] ?? {};
  const { promotion_pos_net, discount_pos_net, contract } = lineItem;

  return (
    <div
      className={`overlay-popup overlay-popup--discount ${isOpen ? "open" : ""}`}
      style={{
        left: isMobile ? "20px" : `${position?.x}px`,
        top: `${(position?.y ?? 0) - headerHeight}px`,
      }}
      ref={ref}
    >
      <div
        className="icon-btn icon-btn__close"
        role="button"
        tabIndex={0}
        onClick={resetLineItemId}
      >
        <span className="visuallyhidden">{i18next.t("CLOSE")}</span>
      </div>
      <div className="overlay-popup__body" onClick={e => e.stopPropagation()}>
        <dl className="dl-account dl-account--checkout">
          <dt className="dl-account__dt">
            <strong>{i18next.t("TOTAL PRICE")}</strong>
          </dt>
          <dd className="dl-account__dd">
            <p className="price__text price__text--crossed">{totalAmount}</p>
          </dd>

          {promotion_pos_net ? (
            <>
              <dt className="dl-account__dt">
                {contract ? (
                  <strong>{i18next.t("DISCOUNT FROM CONTRACT")}</strong>
                ) : (
                  <strong>{i18next.t("DISCOUNT IN POPUP")}</strong>
                )}
              </dt>
              <dd className="dl-account__dd">
                – {getNumberWithCurrency(Math.abs(promotion_pos_net), currency_code)}
              </dd>
            </>
          ) : null}

          {discount_pos_net ? (
            <>
              <dt className="dl-account__dt">
                <strong>{i18next.t("PROMOTION")}</strong>
              </dt>
              <dd className="dl-account__dd">
                – {getNumberWithCurrency(Math.abs(discount_pos_net), currency_code)}
              </dd>
            </>
          ) : null}

          <dt className="dl-account__dt">
            <strong>{i18next.t("NET PRICE")}</strong>
          </dt>
          <dd className="dl-account__dd">{totalAmountDiscount}</dd>
        </dl>
      </div>
    </div>
  );
};

export default DiscountPopup;
