import React from "react";
import i18next from "i18next";
import { Link } from "react-router-dom";
import { PATH } from "../../router/constants/Path";
import Cart from "../../state/vo/Cart";
import { getNumberWithCurrency } from "../../common/scripts/format/number";
import { scrollTop } from "../../layout/scripts/scroll";
import { useLangCountry } from "../../router/hooks/useLangCountry";

interface Props {
  cart: Cart | null;
}

const CartCheckout: React.FC<Props> = ({ cart }) => {
  const langCountry = useLangCountry();
  if (cart === null || cart.attributes === null || cart.counter === 0) {
    return null;
  }

  const totalDiscounted =
    cart.attributes.subtotal_amount_float + cart.attributes.discount_amount_float;

  return (
    <div className="cart-checkout">
      <Link
        to={`${langCountry}/${PATH.CHECKOUT}/${cart.id}`}
        className="btn"
        role="button"
        tabIndex={0}
        onClick={() => scrollTop()}
      >
        <span className="btn__text">{i18next.t("TO CHECKOUT")}</span>
      </Link>

      <div className="cart-checkout__total">
        <p className="cart-checkout-total__label">{i18next.t("TOTAL")}</p>
        <p className="cart-checkout-total__price">
          {getNumberWithCurrency(totalDiscounted, cart.attributes.currency_code)}
        </p>
      </div>
    </div>
  );
};

export default CartCheckout;
