import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import store from "../../state/init/store";
import DiscountPopup from "../../state/vo/DiscountPopup";
import { ResponseOrdersWithIncludes } from "../../commercelayer/vo/orders";
import { filterExternalPromotions } from "../../commercelayer/scripts/filter";
import { dispatch } from "elstr-frontend-4/dist/ElstrCore";

const name = "discountPopup";
const initialState = store.discountPopup;

const discountPopupSlice = createSlice({
  name,
  initialState,
  reducers: {
    setLineItemId(state: DiscountPopup, action: PayloadAction<DiscountPopup["lineItemId"]>) {
      state.lineItemId = action.payload;
    },
    setTotalAmount(state: DiscountPopup, action: PayloadAction<DiscountPopup["totalAmount"]>) {
      state.totalAmount = action.payload;
    },
    setTotalAmountDiscount(
      state: DiscountPopup,
      action: PayloadAction<DiscountPopup["totalAmountDiscount"]>,
    ) {
      state.totalAmountDiscount = action.payload;
    },
    setPosition(state: DiscountPopup, action: PayloadAction<DiscountPopup["position"]>) {
      state.position = action.payload;
    },
    setExternalPromotions(
      state: DiscountPopup,
      action: PayloadAction<DiscountPopup["external_promotions"]>,
    ) {
      state.external_promotions = action.payload;
    },
  },
});

export const setLineItemId = discountPopupSlice.actions.setLineItemId;
export const setTotalAmount = discountPopupSlice.actions.setTotalAmount;
export const setTotalAmountDiscount = discountPopupSlice.actions.setTotalAmountDiscount;
export const setPosition = discountPopupSlice.actions.setPosition;
const setExternalPromotions = discountPopupSlice.actions.setExternalPromotions;

export default discountPopupSlice.reducer;
export const setExternalPromotionsFromOrder = (order: ResponseOrdersWithIncludes) => {
  dispatch(setExternalPromotions(filterExternalPromotions(order)));
};
