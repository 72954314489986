import React, { useEffect, useLayoutEffect, useMemo, useRef } from "react";
import LineItemInputNumber from "./LineItemInputNumber";
import { LineItems } from "../../commercelayer/vo/lineItems";
import { Product } from "../../graphcms/vo/graphCMS";
import i18next from "i18next";
import PfClassName from "../../product/const/PfClassName";
import { Link } from "react-router-dom";
import { deleteLineItem } from "../actions/cartActions";
import AvailabilityAlert from "../../common/components/AvailabilityAlert";
import { useDispatch, useSelector } from "react-redux";
import State from "../../state/vo/State";
import { loadSku } from "../../commercelayer/actions/commercelayerActions";
import { getNumberWithCurrency } from "../../common/scripts/format/number";
import { getProductDirectLocationObject } from "../../product/scripts/link";
import { scrollTop } from "../../layout/scripts/scroll";
import { Customer } from "../../commercelayer/vo/customers";
import {
  setLineItemId,
  setTotalAmount,
  setTotalAmountDiscount,
} from "../../discount/slices/discountPopupSlice";
import debounce from "../../common/scripts/helpers/debounce";
import { useDiscountPopupPosition } from "../../discount/hooks/useDiscountPopup";

interface Props {
  cartId: string;
  lineItem: LineItems;
  product: Product;
  showUnitAmount: boolean;
  showBatchNumber: boolean;
  showAvailabilityAlert: boolean;
  quantityIsEditable: boolean;
  customer: Customer | null;
}

function getPos(ref: HTMLDivElement | null) {
  if (!ref) return null;
  const rect = ref.getBoundingClientRect();
  const absoluteX = rect.left + window.scrollX;
  const absoluteY = rect.top + window.scrollY;
  return { x: absoluteX, y: absoluteY };
}

const LineItem: React.FC<Props> = ({
  cartId,
  lineItem,
  product,
  showUnitAmount,
  showBatchNumber,
  showAvailabilityAlert,
  quantityIsEditable,
  customer,
}) => {
  const commercelayerSkusIndex = useSelector((state: State) => state.commercelayer.skusIndex);
  useEffect(() => {
    if (showAvailabilityAlert) {
      if (!commercelayerSkusIndex[product.sku]) {
        loadSku(product.sku).then();
      }
    }
  });

  const { lineItemId } = useSelector((state: State) => state.discountPopup);
  const isActiveLineItem = useMemo(() => lineItemId === lineItem.id, [lineItemId, lineItem.id]);

  const discountCents = useMemo(() => {
    if (
      lineItem.attributes.discount_cents !== 0 &&
      lineItem.attributes.hasOwnProperty("discount_breakdown")
    ) {
      const discountBreakdown = lineItem.attributes.discount_breakdown ?? {};
      return Object.values(discountBreakdown).reduce((total, promotion) => {
        if (!promotion.hasOwnProperty("coupon_code") && promotion.cents) {
          return total + promotion.cents;
        }
        return total;
      }, 0);
    }
    return 0;
  }, [lineItem.attributes]);
  const hasDiscount = discountCents < 0;

  const { setNewPosition, resetLineItemId } = useDiscountPopupPosition();
  const ref = useRef<HTMLDivElement>(null);
  useLayoutEffect(
    function updatePositionOnResize() {
      function updatePosition() {
        if (!isActiveLineItem) return;
        if (!ref.current) return;
        setNewPosition(getPos(ref.current));
      }

      if ("ResizeObserver" in window) {
        const ro = new ResizeObserver(debounce(updatePosition, 50));

        // defensive disconnect
        ro.disconnect();

        if (!document.body) {
          return;
        }

        ro.observe(document.body);

        // cleanup
        return () => ro.disconnect();
      }
    },
    [isActiveLineItem, setNewPosition],
  );

  const dispatch = useDispatch();
  const totalAmount = getNumberWithCurrency(
    lineItem.attributes.total_amount_float,
    lineItem.attributes.currency_code,
  );
  const totalAmountDiscounted = getNumberWithCurrency(
    lineItem.attributes.total_amount_float - Math.abs(discountCents / 100),
    lineItem.attributes.currency_code,
  );
  return (
    <li className="cart-list__item">
      <Link
        to={getProductDirectLocationObject(product)}
        className="list-item__text-wrap"
        onClick={() => scrollTop()}
      >
        <h3 className={`item-title  pf-dot pf-${PfClassName?.[product?.pfs?.[0]?.name]}`}>
          {product.sku} {product.name}
        </h3>
        <div className="list-item__text">{product.nameSuffix}</div>
      </Link>

      {showAvailabilityAlert && (
        <AvailabilityAlert
          sku={product.sku}
          requestQuantity={lineItem.attributes.quantity}
          customer={customer}
        />
      )}

      <div className="cart-price-wrap">
        {quantityIsEditable && (
          <LineItemInputNumber
            cartId={cartId}
            lineItemId={lineItem.id}
            quantity={lineItem.attributes.quantity}
          />
        )}
        {!quantityIsEditable && !showBatchNumber && (
          <p className="cart-list__quantity">{lineItem.attributes.quantity}</p>
        )}

        {showUnitAmount && (
          <div className="price">
            <p className="price__text">
              {getNumberWithCurrency(
                lineItem.attributes.unit_amount_float,
                lineItem.attributes.currency_code,
              )}
            </p>
          </div>
        )}

        {showBatchNumber && (
          <div className="price">
            <p className="price__text">{`${i18next.t("BATCH")} ${
              lineItem.attributes.metadata?.batchNumber || "-"
            }`}</p>
          </div>
        )}

        {hasDiscount ? (
          <div className="price price--crossed" ref={ref}>
            <button
              id={lineItem.id}
              // defined type to not trigger <form> submission
              type="button"
              className="price__button icon-font icon-font__info"
              onClick={function toggleDiscountPopup() {
                if (lineItemId === lineItem.id) {
                  resetLineItemId();
                } else {
                  const newPos = getPos(ref.current);
                  dispatch(setTotalAmount(totalAmount));
                  dispatch(setTotalAmountDiscount(totalAmountDiscounted));
                  dispatch(setLineItemId(lineItem.id));
                  setNewPosition(newPos);
                }
              }}
            />
            <div className="price-wrap">
              <p className="price__text price__text--crossed">{totalAmount}</p>
              <p className="price__text">{totalAmountDiscounted}</p>
            </div>
          </div>
        ) : (
          <div className="price">
            <p className="price__text">{totalAmount}</p>
          </div>
        )}

        {quantityIsEditable && (
          <div
            className="icon-btn icon-btn__delete"
            onClick={() => deleteLineItem(cartId, lineItem.id)}
            role="button"
            aria-label={`${product.sku} ${product.name} ${i18next.t("REMOVE")}`}
            tabIndex={0}
          >
            <span className="visuallyhidden">
              {`${product.sku} ${product.name} ${i18next.t("REMOVE")}`}
            </span>
          </div>
        )}
      </div>
    </li>
  );
};

export default LineItem;
