import { dispatch } from "elstr-frontend-4/dist/ElstrCore";
import { serviceGetOrder } from "../../commercelayer/services/orders";
import { ResponseOrdersWithIncludes } from "../../commercelayer/vo/orders";
import { ResponseShipmentsListWithIncludes } from "../../commercelayer/vo/shipments";
import { serviceGetOrderShipments } from "../../commercelayer/services/shipments";
import {
  setOrderAddresses,
  setOrderAttributes,
  setOrderId,
  setOrderLineItems,
  setOrderPaymentMethods,
  setOrderRelationships,
  setOrderShipments,
  setOrderShippingMethods,
} from "../slices/orderSlice";
import {
  filterIncludedAddresses,
  filterIncludedLineItems,
  filterIncludedPaymentMethods,
  filterIncludedShippingMethods,
} from "../../commercelayer/scripts/filter";
import LayerIo from "../../common/services/LayerIo";
import { getCountry, getLang } from "../../router/scripts/params";

import { setExternalPromotionsFromOrder } from "../../discount/slices/discountPopupSlice";

declare var window: any;

export async function loadOrder(orderId: string) {
  const responseOrder: ResponseOrdersWithIncludes | null = await serviceGetOrder(orderId);
  if (responseOrder === null) {
    return;
  }
  await setOrderData(responseOrder);
  const responseShipments: ResponseShipmentsListWithIncludes | null =
    await serviceGetOrderShipments(orderId);
  if (responseShipments === null) {
    return;
  }
  await dispatch(setOrderShipments(responseShipments.data));
  await dispatch(setOrderShippingMethods(filterIncludedShippingMethods(responseShipments)));
}

export async function setOrderData(order: ResponseOrdersWithIncludes, onInit: boolean = false) {
  // !!! similar function setCartData
  setExternalPromotionsFromOrder(order);
  await dispatch(setOrderId(order.data.id));
  await dispatch(setOrderAttributes(order.data.attributes));
  await dispatch(setOrderRelationships(order.data.relationships));
  await dispatch(setOrderLineItems(filterIncludedLineItems(order)));
  await dispatch(setOrderPaymentMethods(filterIncludedPaymentMethods(order)));
  await dispatch(setOrderAddresses(filterIncludedAddresses(order)));
}

export async function resetOrder() {
  await dispatch(setOrderAttributes(null));
  await dispatch(setOrderRelationships(null));
  await dispatch(setOrderLineItems([]));
  await dispatch(setOrderShipments([]));
  await dispatch(setOrderShippingMethods([]));
  await dispatch(setOrderPaymentMethods([]));
  await dispatch(setOrderAddresses([]));
}

export async function startPaymentProcess(orderId) {
  const initTransactionResponse = await LayerIo.requestJsonRpc<any, any>(
    "datatrans",
    "initTransaction",
    {
      orderId: orderId,
      lang: getLang(),
      country: getCountry(),
    },
  );

  if (initTransactionResponse.data.transactionId) {
    const datatransUrlStartPay = `${process?.env?.REACT_APP_DATATRANS_URL_START_PAY}${initTransactionResponse.data.transactionId}`;
    window.location.replace(datatransUrlStartPay);
  }
}
