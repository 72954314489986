import { useDispatch } from "react-redux";
import { useCallback } from "react";
import DiscountPopup from "../../state/vo/DiscountPopup";
import { setLineItemId, setPosition } from "../slices/discountPopupSlice";

export const useDiscountPopupPosition = () => {
  const dispatch = useDispatch();

  const setNewPosition = useCallback(
    (newPosition: DiscountPopup["position"]) => {
      dispatch(setPosition(newPosition));
    },
    [dispatch],
  );

  const resetLineItemId = () => dispatch(setLineItemId(""));

  return { setNewPosition, resetLineItemId };
};
