import State from "../vo/State";
import { getIsMobile } from "./layout";
import { getUseCookies } from "../../layout/scripts/cookies";

function initialCartSate() {
  return {
    id: null,
    attributes: null,
    relationships: null,
    counter: 0,
    animate: false,
    lineItemsSkus: [],
    lineItemsNonSkus: [],
    shipments: [],
    shippingMethods: [],
    addresses: [],
    paymentMethods: [],
    bufferBeforeLogin: [],
  };
}

function initialCheckoutState() {
  return {
    gtc: false,
    gtcError: null,
    bstnk: "",
    couponCode: "",
    couponCodeError: null,
    orderProcessing: false,
    orderUpdating: false,
  };
}

const store: State = {
  router: undefined,
  urlParams: { params: {} },
  layout: {
    isMobile: getIsMobile(),
    cookies: getUseCookies(),
    cartOpen: false,
    liveSearchOpen: false,
    overlayOpen: null,
    showConsignmentStockInfo: false,
    sapMaintenanceEnabled: false,
  },
  graphcms: {
    categoriesTree: [],
    productGroups: [],
    productGroupsIndex: {},
    productGroupsHasInit: false,
    products: [],
    productsIndex: {},
    productsHasInit: false,
  },
  login: {
    token: null,
    tokenMarketAll: null,
    pathnameAfterLogin: null,
  },
  account: {
    customer: null,
    addresses: [],
    orders: [],
    preferences: null,
    report: null,
    reportLoading: false,
    advanceSalePromotions: [],
  },
  cart: initialCartSate(),
  search: {
    q: "",
    result: {
      nbHits: 0,
      productHits: [],
      productGroupHits: [],
    },
    resultLive: {
      nbHits: 0,
      productHits: [],
      productGroupHits: [],
    },
  },
  commercelayer: {
    skusIndex: {},
    skusLoading: {},
  },
  checkout: initialCheckoutState(),
  order: {
    id: null,
    attributes: null,
    relationships: null,
    lineItemsSkus: [],
    lineItemsNonSkus: [],
    shipments: [],
    shippingMethods: [],
    paymentMethods: [],
    addresses: [],
  },
  salesagent: {
    login: null,
    customers: [],
    role: null,
    exports: [],
  },
  product: {
    activeSkuColumn: 0,
  },
  consignmentStock: {
    filter: "",
    order: initialCartSate(),
    scanId: null,
    scannedReceivedInventory: [],
    checkout: initialCheckoutState(),
    orderChangeInProgress: false,
  },
  discountPopup: {
    lineItemId: "",
    totalAmount: "",
    totalAmountDiscount: "",
    position: null,
    external_promotions: { currency_code: "", line_items: {} },
  },
};

export default store;
